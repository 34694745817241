.App {
  width: 100vw;
  height: 100vh;
  background-image: url('./images/gradient-background.jpg');
  background-size: cover;
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-content {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.App-footer {
  position: absolute;
  bottom: 1vh;
}

.App-link {
  color: #61dafb;
}